import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { AlertCircle, Home, ArrowLeft } from 'lucide-react';
import { HelmetProvider } from 'react-helmet-async';
import { initGA, logPageView } from './utils/analytics';

// Components that are part of the core bundle
import ErrorBoundary from './components/podcaster/ErrorBoundary';
import PrivateRoute from './components/auth/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import { PodcasterRoutes } from './routes/PodcasterRoutes';
import { ResearchRoutes } from './routes/ResearchRoutes';
import CookieConsent from './components/common/CookieConsent';

// Lazy load all pages
const LandingPage = React.lazy(() => import('./LandingPage'));
const OttoLandingPage = React.lazy(() => import('./pages/otto/OttoLandingPage'));
const SanityCheckLandingPage = React.lazy(() => import('./pages/productlint/ProductLint'));
const AboutPage = React.lazy(() => import('./pages/company/AboutPage'));
const MissionPage = React.lazy(() => import('./pages/company/MissionPage'));
const PricingPage = React.lazy(() => import('./pages/otto/PricingPage'));
const SecurityPage = React.lazy(() => import('./pages/company/SecurityPage'));
const SupportPage = React.lazy(() => import('./pages/company/SupportPage'));
const PrivacyPolicyPage = React.lazy(() => import('./pages/company/PrivacyPolicyPage'));
const TermsOfServicePage = React.lazy(() => import('./pages/company/TermsOfServicePage'));
const LoginPage = React.lazy(() => import('./pages/auth/LoginPage'));
const RegisterPage = React.lazy(() => import('./pages/auth/RegisterPage'));
const PodcasterLandingPage = React.lazy(() => import('./pages/podcaster/PodcasterLandingPage'));

// Loading component for Suspense fallback
const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-deep-indigo"></div>
  </div>
);

const ErrorPage = () => (
  <div className="bg-soft-parchment min-h-screen font-montserrat text-deep-indigo flex items-center justify-center">
    <div className="container mx-auto px-4 text-center">
      <AlertCircle className="w-24 h-24 text-vibrant-coral mx-auto mb-6" />
      <h1 className="font-montserrat text-6xl font-bold mb-6">
        Oops! Page Not Found
      </h1>
      <p className="font-merriweather text-2xl mb-10 max-w-2xl mx-auto">
        The page you're looking for doesn't exist or has been moved.
      </p>
      <div className="flex justify-center space-x-4">
        <Link 
          to="/" 
          className="bg-deep-indigo text-white px-8 py-4 rounded-lg text-xl hover:bg-vibrant-coral transition-colors flex items-center"
        >
          <Home className="mr-2" /> Go Home
        </Link>
        <button 
          onClick={() => window.history.back()} 
          className="bg-warm-sand text-deep-indigo px-8 py-4 rounded-lg text-xl hover:bg-dark-sand transition-colors flex items-center"
        >
          <ArrowLeft className="mr-2" /> Go Back
        </button>
      </div>
    </div>
  </div>
);

// Add this component to track page views
const PageViewTracker = () => {
  const location = useLocation();
  
  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    // TODO: Remove hardcoding and implement proper consent check before production
    const hasConsented = true; // localStorage.getItem('cookieConsent');
    if (hasConsented === true) {
      initGA('G-Q6MRHKN9JH');
    }
  }, []);

  return (
    <HelmetProvider>
      <AuthProvider>
        <ParallaxProvider>
          <Router>
            <ErrorBoundary>
              <PageViewTracker />
              <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                  {/* Public routes */}
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/otto" element={<OttoLandingPage />} />
                  <Route path="/product-lint" element={<SanityCheckLandingPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/register" element={<RegisterPage />} />
                  <Route path="/podcaster" element={<PodcasterLandingPage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/mission" element={<MissionPage />} />
                  <Route path="/privacy" element={<PrivacyPolicyPage />} />
                  <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route path="/support" element={<SupportPage />} />
                  <Route path="/security" element={<SecurityPage />} />
                  <Route path="/error" element={<ErrorPage />} />
                  
                  {/* Protected Podcaster routes */}
                  <Route element={<PrivateRoute />}>
                    {PodcasterRoutes.map(route => (
                      <Route key={route.path} {...route} />
                    ))}
                  </Route>
                  
                  {/* Research routes */}
                  {ResearchRoutes.map(route => (
                    <Route key={route.path} {...route} />
                  ))}
                  
                  {/* Add this at the very end */}
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </Suspense>
              <CookieConsent />
            </ErrorBoundary>
          </Router>
        </ParallaxProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
