import ReactGA from 'react-ga4';

export const initGA = (measurementId) => {
  // TODO: Remove hardcoding and implement proper consent check before production
  const hasConsented = true; // localStorage.getItem('cookieConsent');
  if (hasConsented === true) {
    ReactGA.initialize(measurementId);
  }
};

export const logPageView = () => {
  // TODO: Remove hardcoding and implement proper consent check before production
  const hasConsented = true; // localStorage.getItem('cookieConsent');
  if (hasConsented === true) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }
};

export const logEvent = (category, action, label) => {
  // TODO: Remove hardcoding and implement proper consent check before production
  const hasConsented = true; // localStorage.getItem('cookieConsent');
  if (hasConsented === true) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }
}; 