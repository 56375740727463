import React from 'react';

const DashboardPage = React.lazy(() => import('../pages/podcaster/DashboardPage'));
const NewPodcastPage = React.lazy(() => import('../pages/podcaster/NewPodcastPage'));
const PodcastSettingsPage = React.lazy(() => import('../pages/podcaster/PodcastSettingsPage'));
const NewEpisodePage = React.lazy(() => import('../pages/podcaster/NewEpisodePage'));
const EpisodeListPage = React.lazy(() => import('../pages/podcaster/EpisodeListPage'));
const PersonasPage = React.lazy(() => import('../pages/podcaster/PersonasPage'));

export const PodcasterRoutes = [
  { path: "/podcaster/dashboard", element: <DashboardPage /> },
  { path: "/podcaster/personas", element: <PersonasPage /> },
  { path: "/podcaster/new", element: <NewPodcastPage /> },
  { path: "/podcaster/:slug/settings", element: <PodcastSettingsPage /> },
  { path: "/podcaster/:slug/episodes", element: <EpisodeListPage /> },
  { path: "/podcaster/:slug/episodes/new", element: <NewEpisodePage /> }
]; 