import { client, endpoints } from './api/client';

export const login = async (username, password) => {
  const data = await client.post(endpoints.auth.login(), { username, password });
  localStorage.setItem('token', data.token);
  localStorage.setItem('user', JSON.stringify(data.user));
  return data;
};

export const register = async (username, email, password) => {
  const data = await client.post(endpoints.auth.register(), { username, email, password });
  localStorage.setItem('token', data.token);
  localStorage.setItem('user', JSON.stringify(data.user));
  return data;
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

export const getCurrentUser = () => {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user) : null;
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const isAuthenticated = () => {
  return !!getToken();
};

export const getUserInfo = async () => {
  try {
    const data = await client.get(endpoints.auth.me(), {});
    localStorage.setItem('user', JSON.stringify(data));
    return data;
  } catch (error) {
    if (error.status === 401) {
      logout();
    }
    throw error;
  }
};
