import React from 'react';
const ResearchPage = React.lazy(() => import('../pages/research/ResearchPage'));
const ResearchPostPage = React.lazy(() => import('../pages/research/ResearchPostPage'));
const NeuralNetVisualization = React.lazy(() => import('../pages/research/NeuralNetVisualization'));


export const ResearchRoutes = [
  {
    path: "/research",
    element: <ResearchPage />,
  },
  {
    path: "/research/:slug",
    element: <ResearchPostPage />,
  },
  {
    path: "/research/visualizing-neural-nets",
    element: <NeuralNetVisualization />,
  }
]; 