const API_URL = process.env.REACT_APP_API_URL;
const API_VERSION = 'v1';

export const endpoints = {
  auth: {
    register: () => `/api/${API_VERSION}/auth/users/`,
    login: () => `/api/${API_VERSION}/auth/token/`,
    me: () => `/api/${API_VERSION}/auth/users/me/`,
  },
  podcaster: {
    list: () => `/api/${API_VERSION}/podcaster/podcasts/`,
    create: () => `/api/${API_VERSION}/podcaster/podcasts/`,
    detail: (slug) => `/api/${API_VERSION}/podcaster/podcasts/${slug}/`,
    episodes: (podcastId) => `/api/${API_VERSION}/podcaster/podcasts/${podcastId}/episodes/`,
    personas: () => `/api/${API_VERSION}/podcaster/personas/`,
    addPersona: (slug) => `/api/${API_VERSION}/podcaster/podcasts/${slug}/add_persona/`,
    removePersona: (slug) => `/api/${API_VERSION}/podcaster/podcasts/${slug}/remove_persona/`,
    episode: {
      create: () => `/api/${API_VERSION}/podcaster/episodes/`,
      download: (id) => `/api/${API_VERSION}/podcaster/episodes/${id}/download/`,
      retry: (id) => `/api/${API_VERSION}/podcaster/episodes/${id}/retry/`,
    },
  },
};

class ApiError extends Error {
  constructor(message, status, data) {
    super(message);
    this.status = status;
    this.data = data;
  }
}

export const client = {
  async request(endpoint, options = {}) {
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      ...(token ? { 'Authorization': `Token ${token}` } : {}),
      ...options.headers,
    };

    const response = await fetch(`${API_URL}${endpoint}`, {
      ...options,
      headers,
    });

    const data = await response.json().catch(() => null);

    if (!response.ok) {
      throw new ApiError(
        data?.error || 'API request failed',
        response.status,
        data
      );
    }

    return data;
  },

  get: (endpoint, options = {}) => 
    client.request(endpoint, { method: 'GET', ...options }),

  post: (endpoint, body, options = {}) =>
    client.request(endpoint, { 
      method: 'POST',
      body: JSON.stringify(body),
      ...options,
    }),

  patch: (endpoint, body, options = {}) =>
    client.request(endpoint, {
      method: 'PATCH',
      body: JSON.stringify(body),
      ...options,
    }),

  delete: (endpoint, options = {}) =>
    client.request(endpoint, { method: 'DELETE', ...options }),
};
